import { Box, Button,Typography } from "@mui/material"
import { Document, Page, pdfjs } from "react-pdf";
import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import React from "react";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.10.38/pdf.min.mjs`;

const Resume = ( {pdfUrl}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
      };
    
    return (
        <Box id="resume" sx={{ height: "100%" }}>
            <Typography align="center" variant="h5"> Resume </Typography>
            <Divider />
            <iframe loading="lazy" className="pdf" width="100%" height="500px" allow="autoplay" referrerPolicy="no-referrer"
					    src="https://drive.google.com/file/d/1MfbzaS96VkCNEFYgjjQkSKu7QveCSB6n/preview"
					    title="My Resume"></iframe>
            <a href={pdfUrl} download>
                <Button>Download PDF</Button>
            </a>
        </Box> )
}

export default Resume