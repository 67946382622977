import './App.css';
import React from 'react';
import Project from './components/project';
import About from './components/about';
import Experience from './components/experience';
import Resume from "./components/resume";
import { Box, Stack} from '@mui/material';
import Navbar from './components/navbar';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const pdfUrl = "/Long_Pham.pdf"

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box className="App">
        <Navbar navItems={[
            { name: 'About', to: 'About' },
            { name: 'Experience', to: 'experience'},
            { name: 'Resume', to: 'resume'},
            { name: 'Projects', to: "Project" }]} />
        <About/>
        <Experience/>
        <Resume pdfUrl={pdfUrl}/>
        <Project/>
      </Box>
    </ThemeProvider>
    
  );
}

export default App;
